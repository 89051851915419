@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap");

/* custom scroll bar */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::selection {
  background: rgb(0, 123, 255, 0.3);
}
.content {
  max-width: 100%;
  margin: auto;
  padding: 0 40px;
  min-height: 100vh;
}
.navbars {
  position: fixed;
  width: 100%;
  z-index: 2000;
  padding: 10px 0;
  background-image: linear-gradient(#67a3de, #78dfc1);
  min-height: 100vh;
  top: 0;
  opacity: 0.9;
  display: block;
  transition: all 0.3s ease-in-out;
}
.navbars.sticky {
  background: #111;
  display: none;
  transition: display 1s ease-out;
  padding: 10px 0;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
}
.navbars .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.navbars .logo a {
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  text-decoration: none;
}
/* .navbars .menu-list {
  display: inline-flex;
}
.menu-list li {
  list-style: none;
}
.menu-list li a {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  margin-left: 25px;
  text-decoration: none;
  transition: all 0.3s ease;
}

.menu-list li .active {
  cursor: pointer;
  color: #fff;
  outline: none;
  border: none;
  font-weight: 600;
  border-radius: 0.2vw;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  background-color: rgba(51, 51, 51, 0.51);
  padding-bottom: 0.5rem;
}
.menu-list li a:hover {
  color: #007bff;
}
.menu-list {
  margin: 0;
}

.about {
  padding: 30px 0;
}
.about .title {
  font-size: 38px;
  font-weight: 700;
}
.about p {
  padding-top: 20px;
  text-align: justify;
}
.icon {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: none;
}
.menu-list .cancel-btn {
  position: absolute;
  right: 30px;
  top: 20px;
}*/
.nav__logo {
  height: 350px;
  object-fit: contain;
  opacity: 1;
}

.invisible_button {
  width: 250px;
  height: 250px;
  cursor: pointer;
  background-image: radial-gradient(#78dfc1, #67a3de, transparent);
}
@media (max-width: 1230px) {
  .content {
    padding: 0 60px;
  }
}
@media (max-width: 1100px) {
  .content {
    padding: 0 40px;
  }
}
@media (max-width: 900px) {
  .content {
    padding: 0 30px;
  }
  .menu-list li a {
    font-size: 12px;
  }
}
@media (max-width: 868px) {
  body.disabled {
    overflow: hidden;
  }
  .navbars {
    background-image: linear-gradient(#67a3de, transparent);
  }
  .navbars .content {
    padding-top: 150px;
    flex-direction: column !important;
    justify-content: start;
  }
  .nav__logo {
    height: 100px;
    object-fit: contain;
  }

  .icon {
    display: block;
  }
  .icon.hide {
    display: none;
  }
  .navbars .menu-list {
    position: fixed;
    height: 100vh;
    width: 100%;
    max-width: 400px;
    left: -100%;
    top: 0px;
    display: block;
    padding: 40px 0;
    text-align: center;
    background: #111;
    transition: all 0.3s ease;
  }

  .navbars.show .menu-list {
    left: 0%;
  }
  .navbars .menu-list li {
    margin-top: 45px;
  }
  .navbars .menu-list li a {
    font-size: 23px;
    margin-left: -100%;
    transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .navbars.show .menu-list li a {
    margin-left: 0px;
  }
}
@media (max-width: 380px) {
  .navbars .logo a {
    font-size: 27px;
  }
}
body.disabled {
  overflow: hidden;
  pointer-events: none;
}
body.disabled:not(.navbars) {
  pointer-events: all;
}
