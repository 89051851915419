@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(#6391e7, #78dfc1, #6391e7);
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  margin: 0;
}

#tsparticles {
  width: 100%;
  height: 100%;
  position: fixed;
  margin: 0;
  top: 0;
  left: 0;
  z-index: -1;
}
* {
  font-family: "Montserrat", sans-serif;
}
#tsparticles canvas {
  display: block;
  transform: scale(1);
  opacity: 1;
  transition: opacity 0.8s ease, transform 1.4s ease;
}

.izy_rehetra {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

/*Medium devices (tablets, 768px and up)*/
@media (max-width: 868px) {
  .izy_rehetra {
    zoom: 0.24;
    margin: 0;
  }
  div {
    resize: both;
  }
  div.Table_01 {
    zoom: 100%;
  }
  #tsparticles {
    width: 100%;
    height: 100%;
    position: fixed;
    margin: 0;
    top: 0;
    left: 0;
  }
}

div.Table_01 {
  position: relative;
  left: 0px;
  top: 0px;
  width: 1518px;
  height: 7285px;
}

div.star-v2-01 {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 1518px;
  height: 3768px;
}

div.star-v2-02 {
  position: absolute;
  left: 0px;
  top: 3768px;
  width: 235px;
  height: 3517px;
}

div.star-v2-03 {
  position: relative;
  left: 235px;
  top: 3768px;
  width: 1049px;
  height: 139px;
}

div.star-v2-04 {
  position: absolute;
  left: 1284px;
  top: 3768px;
  width: 234px;
  height: 3517px;
}

div.star-v2-05 {
  position: absolute;
  left: 235px;
  top: 3907px;
  width: 1049px;
  height: 3378px;
}

.section {
  display: flex;
  padding: 0;
  height: 762px;
  max-width: 100%;
  justify-content: stretch;
}
.center {
  display: flex;
  flex: 1 1;
}

.left {
  position: fixed;
  display: block;
  left: 0;
  top: 0;
}
.mainty {
  width: 100%;
  background-color: #1f1247;
}

.fotsy {
  width: 50%;
  background-color: red;
}
/*.lefts {
  display: flex;
  position: fixed;
  width: 100px;
  height: 100%;
}

.rights {
  right: 0;
  display: flex;
  position: fixed;
  width: 100px;
  height: 100%;
}*/

.lefts {
  display: flex;
  justify-content: stretch;
  width: 50px;
  min-height: 100%;
}

.rights {
  display: flex;
  width: 50px;
  min-height: 100%;
}

.lohany {
  width: 100%;
  height: 500px;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
.plane {
  /*background-image: url("../src/img/sra.png");**/

  align-items: center;
  justify-content: center;
  text-align: center;
}

.title__text {
  text-align: center;
  font-size: 63px;
  color: white;
  margin-left: 280px;
  margin-right: 280px;
}

.title__image {
  object-fit: contain;
  width: 100%;
  background-color: red;
}

/*.cockpit {
  margin-left: 200px;
  margin-right: 200px;
  height: 1400px;
  position: relative;
  overflow: hidden;
  text-align: center;
  3130
}*/
.pilote {
  height: auto;
  margin-left: 71px;
  margin-right: 71px;
  border-left: 5px solid white;
  border-right: 5px solid white;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  background-color: white;
  overflow: hidden;
  resize: none;
}
.rambony {
  max-width: 1500px;
  height: 2140px;
  margin-left: 71px;
  margin-right: 71px;
  border-bottom: 20px;
  border-left: 5px solid white;
  border-right: 5px solid white;
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
  background-color: white;
  overflow: hidden;
  resize: none;
}
.rambonys {
  width: 0;
  height: 0;
  position: relative;
  left: 71px;
  border-left: 689px solid transparent;
  border-right: 689px solid transparent;
  border-top: 1800px solid red;
  z-index: 1;
}
.lastImage {
  position: relative;
  width: 90vw;
  object-fit: contain;
  z-index: 2;
}
.cockpit::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 3000px;
  width: 100%;
  border-radius: 100%;
  border-right: 5px solid white;
  border-left: 5px solid #fff;
  background-color: white;
  z-index: 1;
}
.cabin {
  margin-top: -10px;
  margin-left: 71px;
  margin-right: 71px;
}

.fuselage {
  border-right: 5px solid #fff;
  border-left: 5px solid #fff;
  background-color: white;
}
.profile1 {
  object-fit: contain;
  height: 335px;
  margin-left: 150px;
}
.profile {
  object-fit: contain;
  height: 335px;
  margin-left: auto;
}
.profile2 {
  object-fit: contain;
  height: 335px;
  margin-right: 150px;
}
.profile__description1 {
  text-align: left;
  margin-left: 13vw;
  font-size: 14px;
}

.profile__description {
  text-align: left;
  margin-left: 13vw;
  margin-right: 13vw;
  font-size: 14px;
}

.profile__description2 {
  text-align: left;
  margin-right: 13vw;
  font-size: 14px;
}

.profile__nom {
  text-align: center;
}

.container-fluid {
  padding: 0px !important;
}

.reserver1 {
  padding: 20px;
  background-color: #a7abe8 !important;
  margin-top: 80px;
  border-radius: 50px;
  height: auto;
  font-size: 20px;
  width: 30%;
  position: relative;
  z-index: 10;
}
.reserver {
  text-transform: uppercase;
  padding: 10px;
  background-color: #a7abe8 !important;
  margin-top: 80px;
  border-radius: 50px;
  height: auto;
  font-size: 1.5rem;
  width: 40%;
}

.edvbtn {
  padding: 10px;
  background-color: #14d5ef !important;
  margin-top: 10px;
  border-radius: 50px;
  height: auto;
  font-size: 1.3rem;
  width: 50%;
}
.c1 {
  position: absolute;
  top: 519px;
  left: 0;
  object-fit: contain;
  z-index: -1;
}
.ile1 {
  position: absolute;
  top: 49px;
  left: 0;
  object-fit: contain;
  z-index: -1;
}
.ile2 {
  position: absolute;
  top: 110px;
  right: 10px;
  object-fit: contain;
  z-index: -1;
  width: 300px;
}

.ile3 {
  position: absolute;
  top: 410px;
  left: 50px;
  object-fit: contain;
  z-index: -1;
}

.ile4 {
  position: absolute;
  top: 610px;
  right: 0;
  object-fit: contain;
  z-index: -1;
  width: 300px;
}

.avion145 {
  position: absolute;
  top: 110px;
  left: 50px;
  object-fit: contain;
  z-index: -1;
  width: 300px;
}

.about {
  margin-top: 30px;
  left: 187px;
}

.etoile {
  width: auto;
}
.rowMIsyEtoile {
  position: absolute;
  top: 206px;
  left: 100px;
  z-index: -1;
  height: 442px;
}
.replay {
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
}
.plane__title {
  color: white;
  font-weight: bold;
  font-size: 3em;
  opacity: 1 !important;
  z-index: 2000;
}

.salarie {
  height: 516px;
  position: relative;
  left: 108px;
}
.chef {
  height: 612px;
  position: relative;
  margin-top: 100px;
}
.entrepreneur {
  height: 480px;
  position: relative;
  margin-top: -400px;
}

.leftWing {
  position: absolute;
  top: 1055px;
  left: 0;
  height: 1048px;
}

.rightWing {
  position: absolute;
  top: 1055px;
  right: 0;
  height: 1048px;
}

.laFormation {
  font-size: 43px;
  color: black;
}
.textNetflix {
  font-size: 45px;
  color: #2087b0;
}

.laFormation_text {
  text-align: justify;
  margin: auto;
  width: 80%;
  font-size: 1.3rem;
  color: #a8abec;
  font-weight: 400;
}
.laFormation_textDesc {
  text-align: justify;
  margin: auto;
  width: 80%;
  font-size: 1.3rem;
  color: #a8abec;
  font-weight: 400;
}

.lesFondamentaux {
  margin: auto;
  width: 60%;
  font-size: 40px;
  color: #24a5dc;
}

.bienvenue {
  font-size: 43px;
  color: #a8abec;
}
.plane {
  position: relative;
}
.space {
  height: 200px;
}
.wc {
  width: 33.33%;
}
.wbl {
  position: absolute;
  left: 0;
  height: 100vh;
}

.wbr {
  position: absolute;
  right: 0;
  height: 100vh;
}
.pic4 {
  height: auto;
  padding-top: 100px;
}
.title_netflix {
  font-size: 25px;
  color: #168db8;
}
.c2 {
  position: absolute;
  bottom: 0px;
  left: 0;
  z-index: 80;
}
.c3 {
  position: absolute;
  bottom: -100px;
  left: 0;
  z-index: 1;
}
.c4 {
  position: absolute;
  bottom: 350px;
  left: 0;
  z-index: 1;
}
.c5 {
  position: absolute;
  bottom: 600px;
  right: 0;
  z-index: 1;
}
.c6 {
  position: absolute;
  top: 600px;
  right: 0;
  z-index: -1;
}

.cloudTop {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -2;
  opacity: 0.9;
}

.lastButton {
  text-transform: uppercase;
  position: absolute;
  justify-content: center;
  bottom: 20vh;
  width: 30%;
  margin-top: 80px;
  border-radius: 50px;
  height: auto;
  font-size: 25px;
  left: 35%;
  z-index: 32;
  background-color: #a7abe8 !important;
}
.profile__text {
  text-align: justify;
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar:hover {
  cursor: pointer;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
.time {
  display: flex;
  flex-direction: column;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background-color: #ef233c;
  height: auto;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.time__first {
  color: #fff;
}
.time__first,
.time__second {
  margin-bottom: 0 !important;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
a.bandeau {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

.count {
  display: inline;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: white !important;
  margin-bottom: 0 !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.finished {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  background-color: rgba(255, 255, 255, 0.664);
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 9000;
  -webkit-user-select: none; /* Chrome all / Safari all */ /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}

.finished__text {
  font-size: 2.5rem;
  text-align: center;
}
.logoMv {
  /* margin-top: 50px; */
  width: 30%;
}
.logoMvStart {
  margin-top: 50px;
  width: 30%;
}

.module {
  padding: 50px;
  display: flex;
}
.moduleInverse {
  padding: 50px;
  display: flex;
  flex-direction: row-reverse;
}
.moduleImage {
  padding: 30px;
  width: 50%;
}

.moduleText {
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: start;
}

.moduleTextTitle {
  font-weight: 600;
  border-radius: 50px;
  color: white;
  margin: auto;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 2rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.moduleTextSubTitle {
  text-transform: uppercase;
  font-weight: 550;
  font-family: "Montserrat", sans-serif;
  padding-top: 20px;
  margin: auto;
  width: 90%;
  font-size: 2rem;
}
.moduleTextDescription {
  padding-top: 20px;
  margin: auto;
  width: 90%;
  font-size: 1.5rem;
  color: #a8abec;
}
.screenEDV {
  width: 84%;
}
.iconEDV {
  width: 250px;
}
@media (max-width: 1199.98px) {
  .pilote {
    height: 1970px;
    margin-left: 10px;
    margin-right: 10px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    overflow: hidden;
  }
  .cabin {
    margin-left: 10px;
    margin-right: 10px;
  }
  .rowMIsyEtoile {
    position: absolute;
    top: 200px;
    left: 0px;
    z-index: -1;
  }

  .profile1,
  .profile {
    height: 250px;
    margin-left: 0px;
  }

  .profile__description1,
  .profile__description {
    margin-left: 100px;
    margin-right: 100px;
  }

  .profile2 {
    height: 250px;
    margin-right: 0px;
  }

  .profile__description2 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .leftWing {
    position: absolute;
    top: 2255px;
    left: 0;
    height: 808px;
  }

  .rightWing {
    position: absolute;
    top: 2255px;
    right: 0;
    height: 808px;
  }

  .salarie {
    left: 0;
  }
  .chef {
    margin-top: 0;
  }
  .entrepreneur {
    margin-top: 0;
  }

  .reserver {
    margin: 10px;
    border-radius: 50px;
    width: 350px;
    font-size: 14px;
  }

  .edvbtn {
    margin: 10px;
    border-radius: 50px;
    width: 350px;
    font-size: 14px;
  }

  .time {
    height: auto;
    font-size: 10px;
    flex-direction: column;
  }

  .time__first,
  .time__second {
    margin-bottom: 0 !important;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
  }
  .count {
    display: block;
  }

  .finished__text {
    text-align: center;
    font-size: 1.5rem;
  }
}
@media (max-width: 991.98px) {
  /*.cockpit {
    margin-left: 10px;
    margin-right: 10px;
    height: 500px;
  }*/
  .pilote {
    height: 1530px;
    margin-left: 10px;
    margin-right: 10px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    overflow: hidden;
  }

  /*.cockpit::before {
    height: 1000px;
  }*/
  .cabin {
    height: auto;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: -5px;
  }

  .profile1,
  .profile {
    height: 150px;
    margin-left: 0px;
  }

  .profile__description1,
  .profile__description {
    margin-left: 70px;
    margin-right: 70px;
  }

  .profile2 {
    height: 150px;
    margin-right: 0px;
  }

  .profile__description2 {
    margin-left: 0;
    width: 100%;
  }

  .rowMIsyEtoile {
    position: absolute;
    left: 0px;
  }
  .plane__title {
    font-size: 1em;
  }

  .rowMIsyEtoile {
    position: absolute;
    top: 100px;
    left: 0px;
    z-index: -1;
  }

  .reserver {
    margin: 10px;
    border-radius: 50px;
    width: 250px;
    font-size: 12px;
  }
  .edvbtn {
    margin: 10px;
    border-radius: 50px;
    width: 250px;
    font-size: 12px;
  }

  .c1 {
    top: 219px;
  }
  .c3 {
    position: absolute;
    bottom: -50px;
    left: 0;
    z-index: 1;
  }

  .c4 {
    position: absolute;
    bottom: 150px;
    left: 0;
    z-index: 1;
  }

  .c5 {
    position: absolute;
    bottom: 200px;
    right: 0;
    z-index: 1;
  }
  .leftWing {
    position: absolute;
    top: 2055px;
    left: 0;
    height: 388px;
  }

  .rightWing {
    position: absolute;
    top: 2055px;
    right: 0;
    height: 388px;
  }

  .salarie {
    height: 300px;
    left: 0;
  }
  .chef {
    height: 350px;
    margin-top: 0;
  }
  .entrepreneur {
    height: 300px;
    margin-top: 0;
    margin-bottom: -60px;
  }
  .laFormation {
    font-size: 20px;
    color: black;
  }
  .textNetflix {
    font-size: 20px;
  }

  .laFormation_text {
    font-size: 15px;
    color: #a8abec;
  }

  .laFormation_textDesc {
    text-align: justify;
    margin: auto;
    width: 80%;
    font-size: 15px;
    color: #a8abec;
    font-weight: 400;
  }

  .lesFondamentaux {
    width: 100%;
    font-size: 1rem;
    color: #24a5dc;
  }
  .bienvenue {
    font-size: 20px;
    color: #a8abec;
  }
  .space {
    height: 50px;
  }
  .wbl {
    position: absolute;
    left: 0;
    height: 200px;
  }

  .wbr {
    position: absolute;
    right: 0;
    height: 200px;
  }
  .reserver1 {
    width: 180px;
    font-size: 12px;
  }

  .pic4 {
    padding-top: 50px;
  }

  .title_netflix {
    font-size: 14px;
  }
  .lastImage {
    padding-top: -100px;
    width: 95vw;
  }
  .malalaka {
    padding-top: 10px;
  }

  .module {
    padding: 10px;
    flex-wrap: wrap;
  }
  .moduleImage,
  .moduleText {
    width: 100%;
  }

  .moduleInverse {
    padding: 10px;
    flex-wrap: wrap;
  }

  .moduleTextTitle {
    font-size: 1rem;
  }

  .moduleTextSubTitle {
    font-size: 1rem;
  }
  .moduleTextDescription {
    font-size: 1rem;
  }

  .logoMv {
    margin-top: 0px;
    width: 70%;
  }
  .logoMvStart {
    margin-top: 50px;
    width: 60%;
  }
  .replay {
    padding-right: auto;
    padding-left: auto;
    width: 100%;
  }
  .screenEDV {
    width: 100%;
  }
  .iconEDV {
    width: auto;
  }
  .profile__text {
    font-size: 12px;
  }
}

@media (max-width: 767.98px) {
  .pilote {
    height: 1400px;
    margin-left: 10px;
    margin-right: 10px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    overflow: hidden;
  }

  .profile__description1,
  .profile__description {
    margin-top: 20px;
    margin-left: 0;
    width: 100%;
  }

  .lastButton {
    bottom: 0;
    left: 25%;
    width: 200px;
    height: auto;
    font-size: 12px;
    background-color: #a7abe8 !important;
  }
}

@media (max-width: 575.98px) {
  .pilote {
    height: 1150px;
  }

  .cockpit::before {
    height: 1000px;
  }
}

@media (max-width: 320px) {
  .pilote {
    height: 1100px;
  }

  .leftWing {
    position: absolute;
    top: 2555px;
    left: 0;
    height: 288px;
  }

  .rightWing {
    position: absolute;
    top: 2555px;
    right: 0;
    height: 288px;
  }

  .lastButton {
    bottom: 0;
    left: 28%;
    width: 150px;
    height: auto;
    font-size: 12px;
    background-color: #a7abe8 !important;
  }
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.static-bg {
  size: cover;
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  opacity: 0.5;
}

.row1 {
  margin: 10px;
  margin-left: 0px;
  margin-right: 0px;
}

.row__posters {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  padding: 10px;
  /* max-height: 500px; */
  margin-left: 5px;
  object-fit: contain;
}

.row__posters::-webkit-scrollbar {
  display: none;
}

.row__posterMob {
  object-fit: contain;
  width: 90%;
  height: 100%;
  margin-right: 10px;
  transition: transform 450ms;
  padding: 0;
  border-radius: 5px;
}
.row__poster {
  object-fit: contain;
  width: 100%;
  height: 100%;
  margin-right: -80px;
  transition: transform 450ms;
  padding: 0;
  border-radius: 5px;
}

/*.row__poster:hover {
  transform: scale(2.08);
  overflow: visible;
  z-index: 0;
}*/
.slick-arrow {
  color: white !important;
  font-size: 3rem !important;
  background: rgba(0, 0, 0, 0.5) !important;
  height: 100% !important;
  opacity: 2 !important;
}

.slick-next {
  right: 0 !important;
  opacity: 1 !important;
  z-index: 1 !important;
}

/*.slick-next:hover {
  opacity: 0.9 !important;
  transition: opacity 0.3s !important;
}*/

.slick-prev {
  left: 0 !important;
  z-index: 1 !important;
  opacity: 1 !important;
}

/*.slick-prev:hover {
  opacity: 0.9 !important;
  transition: opacity 0.3s !important;
}*/

.slick-sliderssss {
  display: flex;
  align-self: center;
  justify-content: center;
  max-width: auto;
  padding: 10px !important;
}
.slick-slidesss {
  margin: 0 -20px;
}
.slick-slide {
  width: 300px;
  object-fit: contain;
  padding: 5px;
}

.featured {
  width: 100%;
}

@media (max-width: 900px) {
  .row {
    margin: 5px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
.imgCockpit {
  margin-top: 50px;
}

.banner {
  object-fit: contain;
  height: 500px;
  width: 100%;
  color: white;
}

@media (max-width: 480px) {
  .banner {
    height: 200px;
  }
}

@media (max-width: 768px) {
  .banner {
    height: 150px;
  }
}

/* custom scroll bar */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::selection {
  background: rgb(0, 123, 255, 0.3);
}
.content {
  max-width: 100%;
  margin: auto;
  padding: 0 40px;
  min-height: 100vh;
}
.navbars {
  position: fixed;
  width: 100%;
  z-index: 2000;
  padding: 10px 0;
  background-image: linear-gradient(#67a3de, #78dfc1);
  min-height: 100vh;
  top: 0;
  opacity: 0.9;
  display: block;
  transition: all 0.3s ease-in-out;
}
.navbars.sticky {
  background: #111;
  display: none;
  transition: display 1s ease-out;
  padding: 10px 0;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
}
.navbars .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.navbars .logo a {
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  text-decoration: none;
}
/* .navbars .menu-list {
  display: inline-flex;
}
.menu-list li {
  list-style: none;
}
.menu-list li a {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  margin-left: 25px;
  text-decoration: none;
  transition: all 0.3s ease;
}

.menu-list li .active {
  cursor: pointer;
  color: #fff;
  outline: none;
  border: none;
  font-weight: 600;
  border-radius: 0.2vw;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  background-color: rgba(51, 51, 51, 0.51);
  padding-bottom: 0.5rem;
}
.menu-list li a:hover {
  color: #007bff;
}
.menu-list {
  margin: 0;
}

.about {
  padding: 30px 0;
}
.about .title {
  font-size: 38px;
  font-weight: 700;
}
.about p {
  padding-top: 20px;
  text-align: justify;
}
.icon {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: none;
}
.menu-list .cancel-btn {
  position: absolute;
  right: 30px;
  top: 20px;
}*/
.nav__logo {
  height: 350px;
  object-fit: contain;
  opacity: 1;
}

.invisible_button {
  width: 250px;
  height: 250px;
  cursor: pointer;
  background-image: radial-gradient(#78dfc1, #67a3de, transparent);
}
@media (max-width: 1230px) {
  .content {
    padding: 0 60px;
  }
}
@media (max-width: 1100px) {
  .content {
    padding: 0 40px;
  }
}
@media (max-width: 900px) {
  .content {
    padding: 0 30px;
  }
  .menu-list li a {
    font-size: 12px;
  }
}
@media (max-width: 868px) {
  body.disabled {
    overflow: hidden;
  }
  .navbars {
    background-image: linear-gradient(#67a3de, transparent);
  }
  .navbars .content {
    padding-top: 150px;
    flex-direction: column !important;
    justify-content: start;
  }
  .nav__logo {
    height: 100px;
    object-fit: contain;
  }

  .icon {
    display: block;
  }
  .icon.hide {
    display: none;
  }
  .navbars .menu-list {
    position: fixed;
    height: 100vh;
    width: 100%;
    max-width: 400px;
    left: -100%;
    top: 0px;
    display: block;
    padding: 40px 0;
    text-align: center;
    background: #111;
    transition: all 0.3s ease;
  }

  .navbars.show .menu-list {
    left: 0%;
  }
  .navbars .menu-list li {
    margin-top: 45px;
  }
  .navbars .menu-list li a {
    font-size: 23px;
    margin-left: -100%;
    transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .navbars.show .menu-list li a {
    margin-left: 0px;
  }
}
@media (max-width: 380px) {
  .navbars .logo a {
    font-size: 27px;
  }
}
body.disabled {
  overflow: hidden;
  pointer-events: none;
}
body.disabled:not(.navbars) {
  pointer-events: all;
}

