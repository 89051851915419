.row1 {
  margin: 10px;
  margin-left: 0px;
  margin-right: 0px;
}

.row__posters {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  padding: 10px;
  /* max-height: 500px; */
  margin-left: 5px;
  object-fit: contain;
}

.row__posters::-webkit-scrollbar {
  display: none;
}

.row__posterMob {
  object-fit: contain;
  width: 90%;
  height: 100%;
  margin-right: 10px;
  transition: transform 450ms;
  padding: 0;
  border-radius: 5px;
}
.row__poster {
  object-fit: contain;
  width: 100%;
  height: 100%;
  margin-right: -80px;
  transition: transform 450ms;
  padding: 0;
  border-radius: 5px;
}

/*.row__poster:hover {
  transform: scale(2.08);
  overflow: visible;
  z-index: 0;
}*/
.slick-arrow {
  color: white !important;
  font-size: 3rem !important;
  background: rgba(0, 0, 0, 0.5) !important;
  height: 100% !important;
  opacity: 2 !important;
}

.slick-next {
  right: 0 !important;
  opacity: 1 !important;
  z-index: 1 !important;
}

/*.slick-next:hover {
  opacity: 0.9 !important;
  transition: opacity 0.3s !important;
}*/

.slick-prev {
  left: 0 !important;
  z-index: 1 !important;
  opacity: 1 !important;
}

/*.slick-prev:hover {
  opacity: 0.9 !important;
  transition: opacity 0.3s !important;
}*/

.slick-sliderssss {
  display: flex;
  align-self: center;
  justify-content: center;
  max-width: auto;
  padding: 10px !important;
}
.slick-slidesss {
  margin: 0 -20px;
}
.slick-slide {
  width: 300px;
  object-fit: contain;
  padding: 5px;
}

.featured {
  width: 100%;
}

@media (max-width: 900px) {
  .row {
    margin: 5px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
.imgCockpit {
  margin-top: 50px;
}
